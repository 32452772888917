.button-group{
    display: inline-block;
    margin: 20px 0 50px;
    width: 100%;
    border-bottom: 3px solid #567189;
    text-align: left;
}

div.alice-carousel__prev-btn{
    text-align: center;
    font-size: 35px;
    padding-left: 15%;
}
div.alice-carousel__next-btn{
    text-align: center;
    font-size: 35px;
    padding-right: 15%;
}
div.card-portfolio.card{
    border: none;
}

#portfolio-section{
    margin: 5% 0%;
}
.portofolio-description{
    color: #797979;
    padding: 0 20%;
    margin-top: 3%;
    margin-bottom: 5%;
}
@media screen and (max-width: 500px) and (min-width: 320px) {
    .portfolio-image{
        width: 80%;
        height: 400px;
        object-fit: cover;
        margin: auto;
        }
        .portfolio-subtitle{
            font-family: 'Dancing Script', cursive;
            font-size: 25px;
        }
        .portfolio-titlee{
            font-family: 'Belanosima', sans-serif;
            font-size: 35px;
        }
        .portofolio-description{
            color: #797979;
            padding: 0 10%;
            margin-top: 3%;
            margin-bottom: 5%;
        }
    }
@media screen and (max-width: 768px) and (min-width: 501px){
    .portfolio-image{
        width: 90%;
        height: 400px;
        object-fit: cover;
        margin: auto;
        }
        .portfolio-subtitle{
            font-family: 'Dancing Script', cursive;
            font-size: 28px;
        }
        .portfolio-titlee{
            font-family: 'Belanosima', sans-serif;
            font-size: 38px;
        }
        .portofolio-description{
            color: #797979;
            padding: 0 10%;
            margin-top: 3%;
            margin-bottom: 5%;
        }
}

@media screen and (min-width: 769px) {
    .portfolio-image{
        width: 90%;
        height: 400px;
        object-fit: cover;
        margin: auto;
        }
        .portfolio-subtitle{
            font-family: 'Dancing Script', cursive;
            font-size: 30px;
        }
        .portfolio-titlee{
            font-family: 'Belanosima', sans-serif;
            font-size: 40px;
        }
        .portofolio-description{
            color: #797979;
            padding: 0 20%;
            margin-top: 3%;
            margin-bottom: 5%;
        }
}
