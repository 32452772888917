
  .design:hover {
    transform: rotate(360deg);
    transition: 0.4s;
    background: #567189; 
    color: white;
  }


  .design-des {
    font-size: 22px;
    font-weight: 500;
    padding-top: 5%;
  }
  #services.container-fluid{
    height: 300px;
 }
  @media screen and (max-width: 470px) and (min-width: 320px) {
        .design {
          width: 85px;
          height: 85px;
          border-radius: 50%;
          text-align: center;
          line-height: 100px;
          vertical-align: middle;
          padding: 30px;
          color: #567189;
          border-color: #567189;
          border: 1px solid #567189;
        }
        #services {
          margin-top: 10%;
      }
      #services.container-fluid{
        height: 1030px;
      }
      .services-description {
        line-height: 30px;
        font-weight: 600;
        color: #717173;
        text-align: center;
        margin: 6% 7%;
      }
      .col-services.col-md-4 {
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        width: 80%;
        margin: auto;
      }
      .services-icon {
        width: 100%;
        position: relative;
        top: 20px;
        background-color: white;
      }
    }
    @media screen and (max-width: 600px) and (min-width: 471px) {
      .design {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        text-align: center;
        line-height: 100px;
        vertical-align: middle;
        padding: 30px;
        color: #567189;
        border-color: #567189;
        border: 1px solid #567189;
      }
      #services {
        margin-top: 10%;
    }
    #services.container-fluid{
      height: 1030px;
    }
    .services-description {
      line-height: 30px;
      font-weight: 600;
      color: #717173;
      text-align: center;
      margin: 0% 7%;
    }
    .col-services.col-md-4 {
      text-align: center;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      width: 80%;
      margin: auto;
    }
    .services-icon {
      width: 100%;
      position: relative;
      top: 20px;
      background-color: white;
    }
  }
    

    @media screen and (max-width: 768px) and (min-width: 601px) {

      .design {
        width: 95px;
        height: 95px;
        border-radius: 50%;
        text-align: center;
        line-height: 100px;
        vertical-align: middle;
        padding: 30px;
        color: #567189;
        border-color: #567189;
        border: 1px solid #567189;
      }
      #services {
        margin-top: 10%;
    }
    #services.container-fluid{
      height: 890px;
   }

    .services-description {
      line-height: 30px;
      font-weight: 600;
      color: #717173;
      text-align: center;
      margin: 6% 7%;
    }
    .col-services.col-md-4 {
      text-align: center;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      width: 80%;
      margin: auto;
    }
    .services-icon {
      width: 100%;
      position: relative;
      top: 20px;
      background-color: white;
    }
  }
  @media screen and (max-width: 900px) and (min-width: 769px) {

    .design {
      width: 95px;
      height: 95px;
      border-radius: 50%;
      text-align: center;
      line-height: 100px;
      vertical-align: middle;
      padding: 30px;
      color: #567189;
      border-color: #567189;
      border: 1px solid #567189;
    }
    #services {
      margin-top: 10%;
  }
  #services.container-fluid{
    height: 400px;
 }

  .services-description {
    line-height: 30px;
    font-weight: 600;
    color: #717173;
    text-align: center;
    margin: 0% 7%;
  }
  .col-services.col-md-4 {
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    width: 75%;
    margin: auto;
  }
  .services-icon {
    width: 100%;
    position: relative;
    top: 20px;
    background-color: white;
  }
}

@media screen and (min-width: 901px){
  .design {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    padding: 30px;
    color: #567189;
    border-color: #567189;
    border: 1px solid #567189;
  }
  #services {
    margin-top: 10%;
}
#services.container-fluid{
  height: 400px;
}

.services-description {
  line-height: 30px;
  font-weight: 600;
  color: #717173;
  text-align: center;
  margin: 6% 7%;
}
.col-services.col-md-4 {
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.services-icon {
  width: 100%;
  position: relative;
  top: -200px;
  background-color: white;
}

}