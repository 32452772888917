@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');
.wallpaper-image{
    background-image: url("../../assets/construction-tools-placed-wooden-floors.jpg");
    background-position: 50% 45%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 55vh;
    width: 100%;
}
.wallpaper-title{
    padding-top: 5%;
    padding-bottom: 1%;
    padding-left: 10%;
    padding-right: 10%;
    color: white;
    font-weight:900
}
.wallpaper-description{
    font-family: 'Dancing Script', cursive;
    font-size: 28px;
    color:white;
    font-weight: 400;
}
.telephone{
    font-size: 25px;
    color: #fff;
    font-weight: 800;
    padding-right: 2%;
   
}
.or{
    color: #fff;
    font-weight: 800;
    padding-right: 2%;
}
.contact-button{
    padding: 12px 20px;
    cursor: pointer;
    letter-spacing: 1.2px;
    background: rgb(58, 57, 57);
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 800;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border:2px solid white;
}
.other-details{
margin-top: 3%;
}
@media screen and (max-width: 400px) and (min-width: 320px) {
    .wallpaper-title{
        padding-top: 5%;
        padding-bottom: 1%;
        padding-left: 1%;
        padding-right: 1%;
        color: white;
        font-weight:900;
        font-size:20px;
    }
    .wallpaper-description {
        font-family: 'Dancing Script', cursive;
        font-size: 20px;
        color: white;
        font-weight: 500;
        padding: 3% 10%;
    }
    .telephone {
        font-size: 20px;
        color: #fff;
        font-weight: 600;
        padding-right: 2%;
    }

    .contact-button {
        padding: 8px 8px;
        cursor: pointer;
        letter-spacing: 1.2px;
        background: rgb(58, 57, 57);
        color: white;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 800;
        position: relative;
        overflow: hidden;
        z-index: 1;
        border: 2px solid white;
    }
    .other-details{
        margin-top: 6%;
        }
}
@media screen and (max-width: 500px) and (min-width: 401px) {
    .wallpaper-title{
        padding-top: 5%;
        padding-bottom: 1%;
        padding-left: 1%;
        padding-right: 1%;
        color: white;
        font-weight:900;
        font-size:25px;
    }
    .wallpaper-description {
        font-family: 'Dancing Script', cursive;
        font-size: 23px;
        color: white;
        font-weight: 500;
        padding: 0% 10%;
    }
    .telephone {
        font-size: 20px;
        color: #fff;
        font-weight: 600;
        padding-right: 2%;
    }

    .contact-button {
        padding: 8px 8px;
        cursor: pointer;
        letter-spacing: 1.2px;
        background: rgb(58, 57, 57);
        color: white;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 800;
        position: relative;
        overflow: hidden;
        z-index: 1;
        border: 2px solid white;
    }
    .other-details{
        margin-top: 6%;
        }
}
@media screen and (max-width: 600px) and (min-width: 501px) {
    .wallpaper-title{
        padding-top: 5%;
        padding-bottom: 1%;
        padding-left: 10%;
        padding-right: 10%;
        color: white;
        font-weight:900;
        font-size: 28px;
    }
    .wallpaper-description {
        font-family: 'Dancing Script', cursive;
        font-size: 26px;
        color: white;
        font-weight: 400;
        padding: 0% 12%;
    }
    .telephone{
        font-size: 23px;
        color: #fff;
        font-weight: 600;
        padding-right: 2%;
       
    }
    .or{
        color: #fff;
        font-weight: 600;
        padding-right: 2%;
    }
    .contact-button {
        padding: 8px 8px;
        cursor: pointer;
        letter-spacing: 1.2px;
        background: rgb(58, 57, 57);
        color: white;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 800;
        position: relative;
        overflow: hidden;
        z-index: 1;
        border: 2px solid white;
    }
    .other-details{
    margin-top: 4%;
    }
}
@media screen and (max-width: 900px) and (min-width: 601px){
    .wallpaper-title{
        padding-top: 5%;
        padding-bottom: 1%;
        padding-left: 10%;
        padding-right: 10%;
        color: white;
        font-weight:900;
        font-size: 25px;
    }
    .wallpaper-description {
        font-family: 'Dancing Script', cursive;
        font-size: 25px;
        color: white;
        font-weight: 400;
        padding: 0 3%;
    }
    .telephone{
        font-size: 23px;
        color: #fff;
        font-weight: 700;
        padding-right: 2%;
       
    }
    .or{
        color: #fff;
        font-weight: 700;
        padding-right: 2%;
        font-size: 23px;
    }
    .contact-button {
        padding: 8px 8px;
        cursor: pointer;
        letter-spacing: 1.2px;
        background: rgb(58, 57, 57);
        color: white;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 800;
        position: relative;
        overflow: hidden;
        z-index: 1;
        border: 2px solid white;
    }
}
@media screen and (min-width: 901px){
    .wallpaper-title{
        padding-top: 5%;
        padding-bottom: 1%;
        padding-left: 10%;
        padding-right: 10%;
        color: white;
        font-weight:900;
        font-size: 30px;
    }
    .wallpaper-description {
        font-family: 'Dancing Script', cursive;
        font-size: 28px;
        color: white;
        font-weight: 400;
    }
    .telephone{
        font-size: 25px;
        color: #fff;
        font-weight: 700;
        padding-right: 2%;
       
    }
    .or{
        color: #fff;
        font-weight: 700;
        padding-right: 2%;
        font-size: 25px;
    }
    .contact-button {
        padding: 8px 8px;
        cursor: pointer;
        letter-spacing: 1.2px;
        background: rgb(58, 57, 57);
        color: white;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 800;
        position: relative;
        overflow: hidden;
        z-index: 1;
        border: 2px solid white;
    }
    .wallpaper-image{
        background-image: url("../../assets/construction-tools-placed-wooden-floors.jpg");
        background-position: 50% 45%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 55vh;
        width: 100%;
    }
}