#footer{
    background-color: black;
    margin-top: 10%;
}
.list-footer > li > a{
    text-decoration: none;
    color: rgb(116, 115, 115);
}
.footer-container.container{
    text-align: left;
    padding: 5%;
}
.footer-title{
    font-size: 14px;
    font-weight: 700;
    position: relative;
    margin: 0 0 30px;
    padding-left: 15px;
    text-transform: uppercase;
    color: #fff;
    border-left: 3px solid #567189;
}
.list-footer{
    list-style: none;
    display: block;
    float: left;
    padding: 0;
    width: 100%;
}
.list-footer > li {
    font-size: 15px;
    color: rgb(116, 115, 115);
    font-weight: 600;
    line-height: 28px;
    padding-bottom: 5%;
}

.list-footer > li:hover{
    cursor: pointer;
}
svg{
    margin-right: 5px;
    
}
.facebook > a > svg{
    color: #567189;
}
.working-hours{
    color: rgb(116, 115, 115);
    font-size: 15px;
}
.footer-about{
    font-size: 15px;
    color: rgb(116, 115, 115);
   
}
.working-hours-description{
    line-height: 28px;
}
.footer-about-description{
line-height: 28px;
}
.facebook{
    font-size: 20px;
    
}
.facebook-icon{
    background-color: none;
}
