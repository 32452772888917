@import url("https://fonts.googleapis.com/css2?family=Belanosima&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap");

@media screen and (max-width: 337px) and (min-width: 320px){
  .about-first-title {
    font-family: "Dancing Script", cursive;
    font-size: 25px;
  }
  .about-title {
    font-family: "Belanosima", sans-serif;
    font-size: 25px;
  }
  .about-description {
    color: #797979;
    line-height: 1.7em;
    margin-top: 5%;
    padding: 3% 8%;
  }
  .my-image {
    width: 220px;
    height: 270px;
    background-size: cover;
    object-fit: cover;
    padding: 5% 0%;
  }
  #about-container{
margin-top: 100%;
  }
}
@media screen and (max-width: 360px) and (min-width: 338px){
  .about-first-title {
    font-family: "Dancing Script", cursive;
    font-size: 25px;
  }
  .about-title {
    font-family: "Belanosima", sans-serif;
    font-size: 25px;
  }
  .about-description {
    color: #797979;
    line-height: 1.7em;
    margin-top: 5%;
    padding: 3% 8%;
  }
  .my-image {
    width: 220px;
    height: 270px;
    background-size: cover;
    object-fit: cover;
    padding: 5% 0%;
  }
  #about-container{
margin-top: 80%;
  }
}
@media screen and (max-width: 400px) and (min-width: 361px){
  .about-first-title {
    font-family: "Dancing Script", cursive;
    font-size: 25px;
  }
  .about-title {
    font-family: "Belanosima", sans-serif;
    font-size: 25px;
  }
  .about-description {
    color: #797979;
    line-height: 1.7em;
    margin-top: 5%;
    padding: 3% 8%;
  }
  .my-image {
    width: 220px;
    height: 270px;
    background-size: cover;
    object-fit: cover;
    padding: 5% 0%;
  }
  #about-container{
    margin-top: 70%;
  }
}
@media screen and (max-width: 450px) and (min-width: 401px){
  .about-first-title {
    font-family: "Dancing Script", cursive;
    font-size: 25px;
  }
  .about-title {
    font-family: "Belanosima", sans-serif;
    font-size: 25px;
  }
  .about-description {
    color: #797979;
    line-height: 1.7em;
    margin-top: 5%;
    padding: 3% 8%;
  }
  .my-image {
    width: 220px;
    height: 270px;
    background-size: cover;
    object-fit: cover;
    padding: 5% 0%;
  }
  #about-container{
    margin-top: 45%;
  }
}
@media screen and (max-width: 500px) and (min-width: 451px){
  .about-first-title {
    font-family: "Dancing Script", cursive;
    font-size: 25px;
  }
  .about-title {
    font-family: "Belanosima", sans-serif;
    font-size: 25px;
  }
  .about-description {
    color: #797979;
    line-height: 1.7em;
    margin-top: 5%;
    padding: 3% 8%;
  }
  .my-image {
    width: 220px;
    height: 270px;
    background-size: cover;
    object-fit: cover;
    padding: 5% 0%;
  }
  #about-container{
    margin-top: 35%;
  }
}
@media screen and (max-width: 600px) and (min-width: 501px) {
  .about-first-title {
    font-family: "Dancing Script", cursive;
    font-size: 28px;
  }
  .about-title {
    font-family: "Belanosima", sans-serif;
    font-size: 38px;
  }
  .about-description {
    color: #797979;
    line-height: 1.7em;
    margin-top: 5%;
    padding: 3% 8%;
  }
  .my-image {
    width: 270px;
    height: 330px;
    background-size: cover;
    object-fit: cover;
    padding: 5% 0%;
  }
  #about-container{
    margin-top: 100px;
  }
}
@media screen and (max-width: 768px) and (min-width: 601px) {
  .about-first-title {
    font-family: "Dancing Script", cursive;
    font-size: 28px;
  }
  .about-title {
    font-family: "Belanosima", sans-serif;
    font-size: 38px;
  }
  .about-description {
    color: #797979;
    line-height: 1.7em;
    margin-top: 5%;
    padding: 3% 8%;
  }
  .my-image {
    width: 270px;
    height: 330px;
    background-size: cover;
    object-fit: cover;
    padding: 5% 0%;
  }
  #about-container{
    margin-top: 40%;
  }
}

@media screen and (max-width: 830px) and (min-width: 769px) {
  .about-first-title {
    font-family: "Dancing Script", cursive;
    font-size: 28px;
  }
  .about-title {
    font-family: "Belanosima", sans-serif;
    font-size: 38px;
  }
  .about-description {
    color: #797979;
    line-height: 1.7em;
    margin-top: 5%;
    padding-right: 5%;
    font-size: 15px;
  }
  .about-first {
    text-align: left;
  }
  .my-image {
    width: 310px;
    height: 390px;
    background-size: cover;
    object-fit: cover;
    padding-top: 10%
  }

  .dots {
    background: url(../../assets/dot.png) center center repeat;
    width: 360px;
    height: 390px;
    z-index: -1;
    margin-top: 27%;

  }
  .about-img {
    position: absolute;
    z-index: 1;
  }
  #about-container{
    margin-top: 85%;
  }
}
@media screen and (max-width: 900px) and (min-width: 831px) {
  .about-first-title {
    font-family: "Dancing Script", cursive;
    font-size: 28px;
  }
  .about-title {
    font-family: "Belanosima", sans-serif;
    font-size: 38px;
  }
  .about-description {
    color: #797979;
    line-height: 1.7em;
    margin-top: 5%;
    padding-right: 5%;
    font-size: 15px;
  }
  .about-first {
    text-align: left;
  }
  .my-image {
    width: 310px;
    height: 390px;
    background-size: cover;
    object-fit: cover;
    padding-top: 10%
  }

  .dots {
    background: url(../../assets/dot.png) center center repeat;
    width: 360px;
    height: 390px;
    z-index: -1;
    margin-top: 27%;

  }
  .about-img {
    position: absolute;
    z-index: 1;
  }
  #about-container{
    margin-top: 83%;
  }
}
@media screen and (max-width: 1000px) and (min-width: 901px) {
  .about-first-title {
    font-family: "Dancing Script", cursive;
    font-size: 30px;
  }
  .about-title {
    font-family: "Belanosima", sans-serif;
    font-size: 40px;
  }
  .about-description {
    color: #797979;
    line-height: 1.7em;
    margin-top: 5%;
    padding-right: 5%;
    font-size: 17px;
    line-height: 35px;
  }
  .about-first {
    text-align: left;
  }
  .my-image {
    width: 320px;
    height: 400px;
    background-size: cover;
    object-fit: cover;
    padding-top: 10%
  }

  .dots {
    background: url(../../assets/dot.png) center center repeat;
    width: 370px;
    height: 400px;
    z-index: -1;
    margin-top: 16%;

  }
  .about-img {
    position: absolute;
    z-index: 1;
  }
  #about-container{
    margin-top: 3%;
  }
 
}
@media screen and (max-width: 1200px) and (min-width: 1001px) {
  .about-first-title {
    font-family: "Dancing Script", cursive;
    font-size: 30px;
  }
  .about-title {
    font-family: "Belanosima", sans-serif;
    font-size: 40px;
  }
  .about-description {
    color: #797979;
    line-height: 1.7em;
    margin-top: 5%;
    padding-right: 5%;
    font-size: 17px;
    line-height: 35px;
  }
  .about-first {
    text-align: left;
  }
  .my-image {
    width: 320px;
    height: 400px;
    background-size: cover;
    object-fit: cover;
    padding-top: 10%
  }

  .dots {
    background: url(../../assets/dot.png) center center repeat;
    width: 370px;
    height: 400px;
    z-index: -1;
    margin-top: 16%;

  }
  .about-img {
    position: absolute;
    z-index: 1;
  }
  #about-container{
    margin-top: -5%;
  }
 
}
@media screen and (min-width: 1201px) {
  .about-first-title {
    font-family: "Dancing Script", cursive;
    font-size: 30px;
  }
  .about-title {
    font-family: "Belanosima", sans-serif;
    font-size: 40px;
  }
  .about-description {
    color: #797979;
    line-height: 1.7em;
    margin-top: 5%;
    padding-right: 5%;
    font-size: 17px;
    line-height: 35px;
  }
  .about-first {
    text-align: left;
  }
  .my-image {
    width: 320px;
    height: 400px;
    background-size: cover;
    object-fit: cover;
    padding-top: 10%
  }

  .dots {
    background: url(../../assets/dot.png) center center repeat;
    width: 370px;
    height: 400px;
    z-index: -1;
    margin-top: 16%;

  }
  .about-img {
    position: absolute;
    z-index: 1;
  }
  #about-container{
    margin-top: -6%;
  }
}
