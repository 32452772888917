

@media screen and (max-width: 365px) and (min-width: 300px) {
    .bg {
      width: 100%;
      height: 55vh;
      text-align: center;
      background-image: url(../../assets/main2.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      filter: brightness(60%)
    }
    .main-div{
      margin-top: 10%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 20px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 27px;
      font-weight: 600;
    }
    
    .read-button {
      width: 35%;
      height: 10%;
      border-radius: 7px;
      border: 0;
      background-color: #567189;
      margin: 3% auto;
      line-height: 1.5;
    }
    .read-button p {
      color: white;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center;
      padding: 7px;
      margin: 0;
      font-size: 11px;
    }
  }
  
  @media screen and (max-width: 450px) and (min-width: 366px) {
    .bg {
      width: 100%;
      height: 65vh;
      text-align: center;
      background-image: url(../../assets/main2.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      filter: brightness(60%);
    }
    
    .read-button {
      width: 30%;
      height: 10%;
      border-radius: 7px;
      border: 0;
      background-color: #567189;
      line-height: 1.5;
      margin: 3% auto;
    }
    .read-button p {
      color: white;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center;
      padding: 7px;
      margin: 0;
      font-size: 11px;
    }
    .main-div{
      margin-top: 10%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 25px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 32px;
      font-weight: 600;
    }

  }
  @media screen and (max-width: 475px) and (min-width: 451px) {
    .bg {
      width: 100%;
      height: 65vh;
      text-align: center;
      background-image: url(../../assets/main2.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      filter: brightness(60%);
    }
    .read-button {
      width: 30%;
      height: 10%;
      border-radius: 7px;
      margin-right: 2%;
      border: 0;
      background-color: #567189;
      margin-top: 3%;
      line-height: 1.5;
    }
    .read-button p {
      color: white;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center;
      padding: 7px;
      margin: 0;
      font-size: 11px;
    }
    .main-div{
      margin-top: 7%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 25px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 32px;
      font-weight: 600;
      letter-spacing: 3px;
    }

  }
  @media screen and (max-width: 570px) and (min-width: 476px) {
    .bg {
      width: 100%;
      height: 70vh;
      text-align: center;
      background-image: url(../../assets/main2.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      filter: brightness(60%);
    }
    
    .read-button {
      width: 30%;
      border-radius: 7px;
      margin-right: 2%;
      border: 0;
      background-color: #567189;
      height: 35px;
      margin-top: 3%;
    }
    .read-button p {
      color: white;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center;
      margin: 0;
      font-size: 12px;
    }
    .main-div{
      margin-top: 5%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 25px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 32px;
      font-weight: 600;
      letter-spacing: 3px;
    }
    

  }
  @media screen and (max-width: 640px) and (min-width: 571px) {
    .bg {
      width: 100%;
      height: 75vh;
      text-align: center;
      background-image: url(../../assets/main2.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      filter: brightness(60%);
    }
    
    .read-button {
      width: 25%;
      height: 10%;
      border-radius: 7px;
      margin-right: 2%;
      border: 0;
      background-color: #567189;
      height: 35px;
      margin-top: 3%;
    }
    .read-button p {
      color: white;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center;
      margin: 0;
      font-size: 12px;
    }
    .main-div{
      margin-top: 5%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 25px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 32px;
      font-weight: 600;
      letter-spacing: 3px;
    }

  }
  @media screen and (max-width: 850px) and (min-width: 641px) {
    .bg {
      width: 100%;
      height: 80vh;
      text-align: center;
      background-image: url(../../assets/main2.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      filter: brightness(60%);
      background-position: 50%;
    }
    
    .read-button {
      width: 20%;
      height: 10%;
      border-radius: 7px;
      margin-right: 2%;
      border: 0;
      background-color: #567189;
      height: 35px;
      margin-top: 3%;
    }
    .read-button p {
      color: white;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center;
      margin: 0;
      font-size: 12px;
    }
    .main-div{
      margin-top: 7%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 28px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 35px;
      font-weight: 600;
      letter-spacing: 3px;
    }
  }
  @media screen and (max-width: 999px) and (min-width: 851px) {
    .bg {
      width: 100%;
      height: 100vh;
      text-align: center;
      background-image: url(../../assets/main2.jpg);
      filter: brightness(60%);
      background-position: 25% 75%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;

    }
   
    .read-button {
      width: 18%;
      height: 10%;
      border-radius: 7px;
      margin-right: 2%;
      border: 0;
      background-color: #567189;
      height: 48px;
      margin-top: 5%;
    }
    .read-button p {
      color: white;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center;
      margin: 0;
      font-size: 14px;
    }
    .main-div{
      margin-top: 7%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 32px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 39px;
      font-weight: 600;
      letter-spacing: 4px;
    }
    
  }
  @media screen and (min-width: 1000px){
    .bg {
      width: 100%;
      height: 130vh;
      text-align: center;
      background-image: url(../../assets/main2.jpg);
      filter: brightness(70%);
      background-position: 25% 60%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;

    }
   
    .read-button {
      width: 15%;
      height: 10%;
      border-radius: 7px;
      margin-right: 2%;
      border: 0;
      background-color: #567189;
      height: 48px;
      margin-top: 3%;
    }
    .read-button p {
      color: white;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center;
      margin: 0;
      font-size: 14px;
    }
    .main-div{
      margin-top: 7%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 45px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 55px;
      font-weight: 600;
      letter-spacing: 6px;
    }
    
  }
  
  
    .read-button:hover {
      background-image: linear-gradient(
        to right,
        #7ccbec,
        #25aae1,
        #507994,
        #6a7a9c
      );
      box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
    }
   