
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@media screen and (max-width: 365px) and (min-width: 300px) {
    .bg-contact {
      width: 100%;
      height: 55vh;
      text-align: center;
      background-image: url(../../assets/contact\ \(2\).jpg);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
     color:white;
    }
    .main-div-contact{
      margin-top: 10%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 20px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 27px;
      font-weight: 600;
    }
    .hire-image {
        width: 300px;
        margin-top: 7%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 28%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: #567189;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin: 5% auto;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
        text-align: center;
    }
  }
  
  @media screen and (max-width: 450px) and (min-width: 366px) {
    .bg-contact {
      width: 100%;
      height: 65vh;
      text-align: center;
      background-image: url(../../assets/contact\ \(2\).jpg);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
    color: white;
      
    }
    
    .main-div-contact{
      margin-top: 10%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 25px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 32px;
      font-weight: 600;
    }
    .hire-image {
        width: 300px;
        margin-top: 7%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 28%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: #567189;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin: 5% auto;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
        text-align: center;
    }

  }
  @media screen and (max-width: 475px) and (min-width: 451px) {
    .bg-contact {
      width: 100%;
      height: 65vh;
      text-align: center;
      background-image: url(../../assets/contact\ \(2\).jpg);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      color: white;
      
    }
    
    .main-div-contact{
      margin-top: 7%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 25px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 32px;
      font-weight: 600;
      letter-spacing: 3px;
    }
    .hire-image {
        width: 450px;
        margin-top: 7%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 28%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: #567189;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin: 5% auto;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
        text-align: center;
    }

  }
  @media screen and (max-width: 570px) and (min-width: 476px) {
    .bg-contact {
      width: 100%;
      height: 60vh;
      text-align: center;
      background-image: url(../../assets/contact\ \(2\).jpg);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      color: white;
    }
    
    
    .main-div-contact{
      margin-top: 5%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 25px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 32px;
      font-weight: 600;
      letter-spacing: 3px;
    }
    .hire-image {
        width: 450px;
        margin-top: 7%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 28%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: #567189;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin: 5% auto;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
        text-align: center;
    }
    

  }
  @media screen and (max-width: 640px) and (min-width: 571px) {
    .bg-contact {
      width: 100%;
      height: 65vh;
      text-align: center;
      background-image: url(../../assets/contact\ \(2\).jpg);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      color: white;
      
    }
    
    
    .main-div-contact{
      margin-top: 5%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 25px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 32px;
      font-weight: 600;
      letter-spacing: 3px;
    }
    .hire-image {
        width: 450px;
        margin-top: 7%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 28%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: #567189;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin: 5% auto;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
        text-align: center;
    }

  }
  @media screen and (max-width: 850px) and (min-width: 641px) {
    .bg-contact {
      width: 100%;
      height: 70vh;
      text-align: center;
      background-image: url(../../assets/contact\ \(2\).jpg);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      
      background-position: 50%;
      color: white;
    }
    
    
    .main-div-contact{
      margin-top: 7%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 28px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 35px;
      font-weight: 600;
      letter-spacing: 3px;
    }
    .hire-image {
        width: 450px;
        margin-top: 7%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 28%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: #567189;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin: 5% auto;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
        text-align: center;
    }
  }
  @media screen and (max-width: 999px) and (min-width: 851px) {
    .bg-contact {
      width: 100%;
      height: 80h;
      text-align: center;
      background-image: url(../../assets/contact\ \(2\).jpg);
      
      background-position: 25% 75%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      color: white;

    }
   
    
    .main-div-contact{
      margin-top: 7%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 32px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 39px;
      font-weight: 600;
      letter-spacing: 4px;
    }
    .hire-image {
        width: 500px;
        margin-top: 7%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 25%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: #567189;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin: 5% auto;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
        text-align: center;
    }
    
  }
  @media screen and (min-width: 1000px){
    .bg-contact {
      width: 100%;
      height: 65vh;
      text-align: center;
      background-image: url(../../assets/contact\ \(2\).jpg);
      filter: brightness(70%);
      background-position: 25% 60%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin-top: 0%;
      overflow: hidden;
      color: white;

    }
   
    
    .main-div-contact{
      margin-top: 3%;
    }
    .main-subtitle{
      font-family: 'Dancing Script', cursive;
      font-size: 45px;
      text-align: center;
    }
    .main-title{
      font-family: 'Belanosima', sans-serif;
      font-size: 55px;
      font-weight: 600;
      letter-spacing: 6px;
    }
    .hire-image {
        width: 485px;
        margin-top: 40%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    button.hire-button {
        width: 20%;
        padding: 8px 12px;
        border-radius: 5px;
        background-color: #567189;
        color: white;
        font-weight: 600;
        border: none;
        letter-spacing: 2px;
        margin-left: 1%;
    }

    .hire-description {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        line-height: 2;
        margin-top: 3%;
        margin-bottom: 6%;
        font-size: 16px;
    }

    .hire-big {
        font-family: 'Poppins', sans-serif;
        font-size: 38px;
        color: #000000;
        font-weight: 400;
    }
    
  }
  .contact-section{
    text-align: left;
  }
  .contact-us{
    font-family: 'Dancing Script', cursive;
    font-size: 30px;

  }
  .keep-in-touch{
    font-family: 'Belanosima', sans-serif;
    font-size: 40px;
    padding-bottom: 5%;
  }
   

.form-wrapper>input {
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px px;
    transition: all 0.3s ease;
    height: 50px;
    line-height: 46px;
    margin-bottom: 20px;
    outline: none;
    color: #1f1f25;
    font-size: 15px;
    letter-spacing: 0.1px;
}

.form-wrapper>textarea {
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 2px solid rgba(0, 0, 0, .1);
    border-radius: 5px;
    transition: all .3s ease;
    height: 50px;
    line-height: 46px;
    margin-bottom: 20px;
    outline: none;
    color: #1f1f25;
    font-size: 15px;
    letter-spacing: .1px;
    height: 120px;
}

.hire-tel {
    color: #567189;
    font-weight: 600;
}

.hire-email {
    color: #567189;
    font-weight: 600;
}

.form-wrapper>input:focus {
    border-color: #567189;
}

.form-wrapper>textarea:focus {
    border-color: #567189;
}



button.hire-button:hover {
    border: 2px solid #567189;
    color: black;
    background-color: white;
}
#hire-container {
  margin-top: 10%;
  margin-bottom: 5%;
}
.contact-icons{
  color: #fff;
  background-color: #567189;
  border-radius: 50%;
  text-align: center;
  width: 40px;
  line-height: 50px;
  height: 40px;
  padding: 10px;
}
.all-icons.col-lg-6{
  margin-top: 10%;
}
.div-icons{
  display: flex;
  padding: 2% 0%;
}
.p-icons{
color:rgb(116, 115, 115);
padding:2%;
font-size: 16px;

}
@media screen and (min-width: 992px) {
  .all-icons.col-lg-6{
padding:  5% 8%;
  }
}