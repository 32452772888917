@import url('https://fonts.googleapis.com/css2?family=Belanosima&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');
#service-section{
    background-image: url("../../assets/pattren.png");
    margin-top: 5%;
    padding-bottom: 5%;
}
.service-card:hover .icon{
    color: white;
    background-color: #567189;
}
.icon{
 
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #fbfbfb;
    text-align: center;
    margin: 0 0 15px;
    font-size: 30px;
    line-height: 75px;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    position: relative;
    z-index: 0;
    color:#567189
}
.service-card.card{
    padding: 25px 30px 30px;
    background-color: white;
    width: 100%;
    margin: 0 15px 40px;
}
 .service-title{
    text-align: left;
}
.services-title{
    font-family: 'Belanosima', sans-serif;
    font-size: 35px;
}
.services-subtitle{
    font-family: 'Dancing Script', cursive;
    font-size: 28px;
}
.service-description{
    color: #797979;
    padding: 0 15%;
    margin-top: 3%;
    margin-bottom: 5%;
}
.each-service-description{
    text-align: left;
    color: #797979;
}
@media screen and (max-width: 500px) and (min-width: 320px) {
    .services-title {
        font-family: 'Belanosima', sans-serif;
        font-size: 28px;
    }
    .services-subtitle{
        font-family: 'Dancing Script', cursive;
        font-size: 28px;
    }
    .service-description {
        color: #797979;
        padding: 0 10%;
        margin-top: 3%;
        margin-bottom: 5%;
    }
    .service-card.card {
        padding: 20px 25px 25px;
        background-color: white;
        width: 100%;
        margin: 0 15px 40px;
}
.icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #fbfbfb;
    text-align: center;
    margin: 0 0 10px;
    font-size: 30px;
    line-height: 75px;
    transition: all 0.3s ease-in;
    position: relative;
    z-index: 0;
    color: #567189;
}
}
@media screen and (max-width: 768px) and (min-width: 501px) {
    .services-subtitle {
        font-family: 'Dancing Script', cursive;
        font-size: 28px;
    }
    .services-title {
        font-family: 'Belanosima', sans-serif;
        font-size: 38px;
    }
    .service-description {
        color: #797979;
        padding: 0 10%;
        margin-top: 3%;
        margin-bottom: 5%;
    }
    .service-card.card {
        padding: 20px 25px 25px;
        background-color: white;
        width: 100%;
        margin: 0 15px 40px;
}
.icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #fbfbfb;
    text-align: center;
    margin: 0 0 10px;
    font-size: 30px;
    line-height: 75px;
    transition: all 0.3s ease-in;
    position: relative;
    z-index: 0;
    color: #567189;
}
}
@media screen and (max-width: 900px) and (min-width: 769px) {
    .services-subtitle {
        font-family: 'Dancing Script', cursive;
        font-size: 28px;
    }
    .services-title {
        font-family: 'Belanosima', sans-serif;
        font-size: 38px;
    }
    .service-description {
        color: #797979;
        padding: 0 10%;
        margin-top: 3%;
        margin-bottom: 5%;
    }
    .service-card.card {
        padding: 20px 25px 25px;
        background-color: white;
        width: 100%;
        margin: 0 15px 40px;
}
.icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #fbfbfb;
    text-align: center;
    margin: 0 0 10px;
    font-size: 30px;
    line-height: 75px;
    transition: all 0.3s ease-in;
    position: relative;
    z-index: 0;
    color: #567189;
}
}

