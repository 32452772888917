@import url("https://fonts.googleapis.com/css2?family=Belanosima&display=swap");
/* Default styling for the navbar */

.list {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.items {
  margin-right: 1rem;
}



.menu-but {
  display: none; /* Hide the button by default */
  background-color: transparent;
  color: black;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
.navbarLinks:hover{
  cursor: pointer;
  }
@media screen and (max-width: 600px) and (min-width: 320px) {
  .list {
    margin: auto;
    list-style-type: none;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 49px;
    background-color: rgba(10, 10, 6, 0.8);
    flex-direction: column;
    height: auto;
    position: absolute;
    right: 0%;
    z-index: 2;
    color: white;
  }
  .navbarLinks {
    color: white;
    text-decoration: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-family: "Belanosima", sans-serif;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
  }
  li.items {
    display: inline-block;
    padding: 10px 5px;
    position: relative;
    text-decoration: none;
    letter-spacing: 0.15em;
  }
  .navbarLinks > img {
    padding-left: 0rem;
  }
  .menu-but {
 
    position: absolute;
    top: 5%;
    right: 2rem;
    display: block;
  }
  .logo img {
    height: 50px; /* Adjust the height of the logo as needed */
    position: absolute;
    top: 5%;

  }
  #navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 1rem;
    background-color: white;
  }
}

@media screen and (max-width: 900px) and (min-width: 601px) {
  .list {
    margin: auto;
    list-style-type: none;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 49px;
    background-color: rgba(10, 10, 6, 0.8);
    flex-direction: column;
    height: auto;
    position: absolute;
    right: 0%;
    z-index: 2;
    color: white;
  }
  li.items {
    display: inline-block;
    padding: 10px 5px;
    position: relative;
    text-decoration: none;
    letter-spacing: 0.15em;
  }
  .navbarLinks {
    color: white;
    text-decoration: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-family: "Belanosima", sans-serif;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
  }
  .navbarLinks > img {
    padding-left: 1rem;
  }
  .menu-but {
    position: absolute;
    top: 5%;
    right: 2rem;
    display: block;
  }
  .logo img {
    height: 50px; /* Adjust the height of the logo as needed */
    position: absolute;
    top: 5%;

  }
  #navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 1rem;
    background-color: white;
  }
}
/* Media query for screens larger than 900px */
@media screen and (min-width: 901px) {
  .menu-but {
    display: none; /* Hide the button for larger screens */
  }
  .list {
    display: flex; /* Show the navigation links in a row */
  }
  .navbarLinks > img {
    padding-left: 4rem;
  }
  .navbarLinks.contact {
    margin-right: 4rem;
    color: white;
    background-color: black;
    padding: 8% 12%;
  }
  .navbarLinks.contact:hover{
    margin-right: 4rem;
    color: black;
    background-color: white;
    padding: 8% 12%;
    border: 1px solid black;
  }
  .logo img {
    height: 60px; /* Adjust the height of the logo as needed */
  }
  .navbarLinks {
    color:black;
    text-decoration: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-family: "Belanosima", sans-serif;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
  }
  li.items {
    display: inline-block;
    padding: 0px 5px;
    position: relative;
    text-decoration: none;
    letter-spacing: 0.15em;
  }
  #navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 1rem;
    background-color: white;
  }
}

